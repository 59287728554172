import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { font, color, breakpoints } from '../../utils/constants'

const ButtonInternal = styled(props => <Link {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13rem;
  height: 13rem;
  font-family: ${font.black};
  font-size: 1.666666667rem;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  color: ${color.baseDark};
  background-color: ${color.baseLight};
  border: 8px solid ${color.baseDark};
  border-radius: 50%;
  box-shadow: none;
  transform: rotate(-22deg);
  transition: transform 0.9s ease-in-out, box-shadow 0.9s ease-in-out;

  &:hover {
    transform: rotate(698deg);
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.4);
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 11rem;
    height: 11rem;
    border: 5px solid ${color.baseDark};
  }
`

export default ButtonInternal
