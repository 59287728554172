import React from 'react'
import styled from 'styled-components'
import { spacing, breakpoints } from '../utils/constants'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Video from '../components/Video'
import HomeVideo from '../assets/videos/home.mp4'
import ProjectsTease from '../assets/images/projects-teaser@2x.jpg'
import ButtonInternal from '../components/ButtonInternal'

const ParadigmIntro = styled.div`
  max-width: 1124px;
  margin: 0 auto;
  padding: 0 ${spacing.sm};
  text-align: center;
`

const ParadigmIntroText = styled.p`
  font-size: 3.555555556rem;
  line-height: 1.25;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 1.9rem;
  }
`

const ProjectsTeaser = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 77.2916667%;
  background-image: url('${ProjectsTease}');
  background-size: cover;
`

const TeaserButtonContainer = styled.div`
  position: absolute;
  top: calc(50% - 6.5rem);
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Video Src={HomeVideo} />
    <ParadigmIntro>
      <ParadigmIntroText>
        <strong>Paradigm</strong> is an art exhibition of the Wrong Biennale
        focused on showcasing artists on the cusp of new paradigms of art and
        digital media.
      </ParadigmIntroText>
    </ParadigmIntro>
    <ProjectsTeaser>
      <TeaserButtonContainer>
        <ButtonInternal to="/projects">
          View
          <br />
          Projects
        </ButtonInternal>
      </TeaserButtonContainer>
    </ProjectsTeaser>
  </Layout>
)

export default IndexPage
